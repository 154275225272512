<script lang="jsx">
import { defineComponent } from 'vue'
import DiscountLabel from './UI/DiscountLabel.vue'
import CamelCasePrice from './UI/CamelCasePrice.vue'
import ProductItemSheinClubDiscount from './ProductItemSheinClubDiscount'
import PriceReductionAmountLabel from './UI/PriceReductionAmountLabel.vue'
import { handlePriceHtml } from 'public/src/pages/components/product/item_v2/js/utils.js'
const { GB_cssRight } = typeof window !== 'undefined' ? window.gbCommonInfo : {}

export default defineComponent({
  name: 'ProductItemNormalPrice',
  props: {
    newFlashPromotion: {
      type: Object,
      default: () => ( {} )
    },
    config: {
      type: Object,
      default: () => ( {} )
    },
    salePrice: {
      type: Object,
      default: () => ( {} )
    },
    retailPrice: {
      type: Object,
      default: () => ( {} )
    },
    // 降价金额
    discountAmount: {
      type: Object,
      default: () => ( {} )
    },
    pretreatInfo: {
      type: Object,
      default: () => ( {} )
    },
    multiColumn: {
      type: Boolean,
      default: false
    },
    constantData: {
      type: Object,
      default: () => ( {} )
    },
    isPromoPrice: {
      type: Boolean,
      default: false
    },
    sheinClubPrice: {
      type: Object,
      default: () => ({}),
    },
    sheinClubDiscountValue: {
      type: [String, Number],
      default: '',
    },
    // 小号的付费会员折扣标签
    showSmallSheinClubLabel: {
      type: Boolean,
      default: false  
    },
    bottomInfoRightGap: {
      type: String,
      default: ''
    },
    // 显示驼峰价格样式
    showCamelPrice: {
      type: Boolean,
      default: false
    },
    // 展示在价格上方的新型闪购标签
    newFlashLabelUpPriceText: {
      type: String,
      default: ''
    },
    // 折扣信息
    discountInfo: {
      type: Object,
      default: () => ({})
    },
    hasRightIcon: {
      type: Boolean,
      default: false
    },
    curData: {
      type: Object,
      default: () => ( {} )
    },
    // 来源组件
    compSrc: {
      type: String,
      default: ''
    },
    // 是否隐藏价格的fsp-element点位
    hideFsp: {
      type: Boolean,
      default: false,
    }
  },
  render({ $props: props, $data: data }) {
    const { constantData, config, newFlashPromotion, pretreatInfo, sheinClubPrice, salePrice, retailPrice, isPromoPrice, showSmallSheinClubLabel, sheinClubDiscountValue, multiColumn, bottomInfoRightGap, showCamelPrice, newFlashLabelUpPriceText, discountInfo, hasRightIcon, discountAmount, curData, compSrc, hideFsp } = props || {}
    const { lang, currency, currencies, PUBLIC_CDN } = constantData
    const { showPriceReductionLabel } = config || {}
    const { flashZonePromotion } = curData || {}

    // 币种符号
    const priceSymbol = currencies?.[lang]?.[currency]?.symbol_left

    // 分人群专区闪购
    const flashZonePrice = flashZonePromotion?.price || {}
    const flashZonePriceWithSymbol = flashZonePrice.amountWithSymbol || ''

    // 闪购，限时折扣 图标
    const promotionIconList = (() => {
      let list = []
      if(config.hideIconBeforePrice || config.standardPrice){
        return []
      }
      pretreatInfo.mixPromotionInfo?.forEach(i => {
        if (!i.icon) return
        // app专属图标无需展示
        if (i.typeId !== 8) {
          if (i.typeId == 10) {
            // 闪电标（1: 普通闪购, 4: 分人群定价专区闪购, 5: 不分人群定价专区闪购）
            if (([1, 5].includes(+i.flash_type) || +i.flash_type === 4 && flashZonePriceWithSymbol) && !config.hideFlashSaleIcon) {
              list.push(i.icon)
            }
            return
          }
          list.push(i.icon)
        } else if (i.typeId === 3) {
          if (!config.hideLimitedTimeIcon) list.push(i.icon)
        }
      })
      const current = list.length ? [list[0]] : []
      return current
    })()
    // 新型闪购价
    const newFlashPrice = newFlashPromotion?.price || {}
    const showNewFlashPrice = config.showNewFlashPriceOnSale && newFlashPrice.amountWithSymbol
    // 付费会员价
    const showSheinClubPrice = config.showSheinClubPriceOnSale && sheinClubPrice.amountWithSymbol
    // 有无折扣
    const isDiscount = (() => {
      const salePriceAmount = Number(salePrice.amount)
      const retailPriceAmount = Number(retailPrice.amount)
      const newFlashPriceAmount = Number(newFlashPrice.amount)
      const flashZonePriceAmount = Number(flashZonePrice.amount)

      // 展示付费会员价时，不展示划线价
      return showSheinClubPrice
        ? false
        : showNewFlashPrice
          ? retailPriceAmount > newFlashPriceAmount
          : flashZonePriceAmount > salePriceAmount || retailPriceAmount > salePriceAmount
    })()
    // 展示零售价
    const showRetailPrice = !config.standardPrice && !showPriceReductionLabel && !config.hideRetailPrice && retailPrice.amountWithSymbol ? isDiscount : false
    // 价格的一些自定义样式
    const priceStyle = (() => {
      const {
        pricesNewline,
        salePriceFontSize,
        salePriceColor,
        salePriceFontWeight,
        lineThroughPriceFontSize,
        lineThroughPriceScale
      } = config.style || {}

      const style = {
        salePrice: {},
        retailPrice: {},
        pricesNewline
      }

      salePriceFontSize && (style.salePrice.fontSize = salePriceFontSize)
      salePriceColor && (style.salePrice.color = salePriceColor)
      salePriceFontWeight && (style.salePrice.fontWeight = salePriceFontWeight)
      lineThroughPriceFontSize && (style.retailPrice.fontSize = lineThroughPriceFontSize)
      lineThroughPriceScale && (style.retailPrice.transform = `scale(${lineThroughPriceScale})`)

      // wiki: pageId=1347754607, 处理价格颜色
      config.standardPrice && (style.salePrice.color = '#222')
      return style
    })()
    const showDiscountLabel = config.showDiscountLabelAfterPrice && discountInfo?.show && !config.standardPrice
    const showSheinClubDiscount = showSmallSheinClubLabel && !config.standardPrice
    const camelPriceStyle = config.style?.camelPriceStyle ?? {}
    // 促销图标（闪购，限时折扣）
    const promotionIconListEl = promotionIconList.map((promoIcon, index) => {
      return (
        <i
          key={index}
          class={['suiiconfont', promoIcon]}
        ></i>
      )
    })

    // 未知价格
    const unknownPriceEl = config.showUnknownPrice ? (
      <span class="normal-price__unknown-price">{ priceSymbol }??</span>
    ) : null

    let salePriceEl = null
    const compSrcFlag = compSrc ? `fsp__${compSrc}` : ''
    if (showSheinClubPrice) { // 付费会员价格-占用现售价的坑位
      const { currencySymbolPrefix, numberBeforeDot, numberAfterDot, currencySymbolSuffix } = handlePriceHtml(sheinClubPrice.amountWithSymbol)
      const classList = ['normal-price__sale-price', 'normal-price__sale-price_sheinclub']
      if (!showCamelPrice) {
        classList.push(compSrcFlag)
      }
      salePriceEl = (
        <CamelCasePrice
          class={classList}
          show-camel-price={showCamelPrice}
          currency-symbol-prefix={currencySymbolPrefix}
          number-before-dot={numberBeforeDot}
          number-after-dot={numberAfterDot}
          currency-symbol-suffix={currencySymbolSuffix}
          origin-string={sheinClubPrice.amountWithSymbol}
          camel-price-style={camelPriceStyle}
          comp-src={compSrc}
          style={priceStyle.salePrice}
        />
      )
    } else if (showNewFlashPrice) { // 新型闪购价-占用现售价的坑位
      const { currencySymbolPrefix, numberBeforeDot, numberAfterDot, currencySymbolSuffix } = handlePriceHtml(newFlashPrice.amountWithSymbol)
      const classList = ['normal-price__sale-price', 'normal-price__sale-price_flash']
      salePriceEl = (
        <CamelCasePrice
          class={classList}
          show-camel-price={showCamelPrice}
          currency-symbol-prefix={currencySymbolPrefix}
          number-before-dot={numberBeforeDot}
          number-after-dot={numberAfterDot}
          currency-symbol-suffix={currencySymbolSuffix}
          origin-string={newFlashPrice.amountWithSymbol}
          camel-price-style={camelPriceStyle}
          comp-src={compSrc}
          style={priceStyle.salePrice}
        />
      )
    } else if (flashZonePriceWithSymbol) { // 分人群专区闪购价-占用现售价的坑位
      const { currencySymbolPrefix, numberBeforeDot, numberAfterDot, currencySymbolSuffix } = handlePriceHtml(flashZonePriceWithSymbol)
      const classList = ['normal-price__sale-price', 'normal-price__sale-price_flash']
      if (!showCamelPrice) {
        classList.push(compSrcFlag)
      }
      salePriceEl = (
        <CamelCasePrice
          class={classList}
          show-camel-price={showCamelPrice}
          currency-symbol-prefix={currencySymbolPrefix}
          number-before-dot={numberBeforeDot}
          number-after-dot={numberAfterDot}
          currency-symbol-suffix={currencySymbolSuffix}
          origin-string={flashZonePriceWithSymbol}
          camel-price-style={camelPriceStyle}
          comp-src={compSrc}
          style={priceStyle.salePrice}
        />
      )
    } else if (salePrice.amountWithSymbol) { // 现售价
      const { currencySymbolPrefix, numberBeforeDot, numberAfterDot, currencySymbolSuffix } = handlePriceHtml(salePrice.amountWithSymbol)
      const classList = ['normal-price__sale-price']
      const showPromoColor = isPromoPrice && !showSmallSheinClubLabel
      // 限时直降活动，固定显示红色价格
      const showSellerLimitedLabel = pretreatInfo?.mixPromotionInfo?.some(item => +item?.typeId === 31 && item?.promotion_logo_type === 3)

      if (isDiscount || showPromoColor || !!showSellerLimitedLabel) {
        classList.push('normal-price__sale-price_promo')
      }
      if (!showCamelPrice) {
        classList.push(compSrcFlag)
      }
      salePriceEl = (
        <CamelCasePrice
          class={classList}
          tabindex="0"
          aria-label={salePrice.amountWithSymbol}
          role="none"
          style={priceStyle.salePrice}
          show-camel-price={showCamelPrice}
          currency-symbol-prefix={currencySymbolPrefix}
          number-before-dot={numberBeforeDot}
          number-after-dot={numberAfterDot}
          currency-symbol-suffix={currencySymbolSuffix}
          camel-price-style={camelPriceStyle}
          comp-src={compSrc}
          origin-string={salePrice.amountWithSymbol}
        />
      )
    }

    // 零售价
    const retailPriceEl = showRetailPrice ? (
      <span
        class={[
          'normal-price__origin-price', 
          { 
            'normal-price__origin-price_newline': priceStyle.pricesNewline 
          }
        ]}
        tabindex="0"
        role="none"
        aria-label={retailPrice.amountWithSymbol}
        style={priceStyle.retailPrice}
      >
        {retailPrice.amountWithSymbol}
      </span>
    ) : null

    // 降价金额
    const priceReductionLabelEl = !config.standardPrice && showPriceReductionLabel ? (
      <PriceReductionAmountLabel
        constant-data={constantData}
        amount-with-symbol={discountAmount?.amountWithSymbol}
      />
    ) : null

    let discountEl = null
    if (showSheinClubDiscount) { // 付费会员折扣
      const sheinClubStyle = {}
      if (config.showSheinClubDiscountValuePriceRight && bottomInfoRightGap) {
        if(GB_cssRight) {
          sheinClubStyle.left = `-${ bottomInfoRightGap }`
        } else {
          const right = -0.29 - bottomInfoRightGap.replace('rem', '') + 'rem'
          sheinClubStyle.right = right
        }
      }
      discountEl = (
        <ProductItemSheinClubDiscount
          discount-value={sheinClubDiscountValue}
          class={[
            'normal-price__sheinclub-discount', 
            { 
              'normal-price__sheinclub-right': config.showSheinClubDiscountValuePriceRight 
            }
          ]}
          style={sheinClubStyle}
          public-cdn={PUBLIC_CDN}
          type="small"
        />
      )
    } else if (showDiscountLabel) { // 折扣标签
      discountEl = (
        <DiscountLabel
          type={config.showNewDiscountLabel && !constantData.IS_RW ? 'newDiscount' : 'discount'}
          discount-info={discountInfo}
        />
      )
    }

    return (
      <div
        class={[
          {'fsp-element': !hideFsp},
          'normal-price',
          {
            'normal-price_small-size': multiColumn,
            'normal-price__camel-price': showCamelPrice,
            'normal-price_small-size-height': config.hideDiscountLabel && multiColumn,
            'normal-price_wider': !hasRightIcon,
          },
          data.class,
          data.staticClass,
        ]}
        style={[data.style, data.staticStyle]}
      >
        {
          newFlashLabelUpPriceText 
            ? <div class="normal-price__flash-label">{newFlashLabelUpPriceText}</div> 
            : null
        }
        <div 
          class={[
            'normal-price__content',
            {
              'normal-price__content_hide': config.hideDiscountLabel,
            }
          ]}
        >
          {promotionIconListEl}
          {unknownPriceEl}
          {salePriceEl}
          {retailPriceEl}
          {priceReductionLabelEl}
          {discountEl}
        </div>
      </div>
    )
  },
})
</script>

<style lang="less">
.normal-price {
  .flexbox();
  flex-direction: column;
  max-width: 80%;
  &__flash-label {
    .font-dpr(20px);
    color: @sui_color_discount;
    line-height: 0.32rem;
  }
  &__content {
    .flexbox();
    .align-center();
    .font-dpr(28px);
    flex-wrap: wrap;
    &_hide {
      height: 0.48rem;
      overflow: hidden;
    }
    >span {
      .text-overflow();
    }
  }
  &_small-size {
    .font-dpr(24px);
    line-height: 0.3733rem;
  }
  /* stylelint-disable-next-line selector-class-pattern */
  &_small-size-height {
    height: 0.3733rem;
  }
  &_wider {
    max-width: 100%;
  }
  >span {
    .text-overflow();
    &:first-of-type {
      flex-shrink: 0;
    }
  }
  [class*="iconfont"] {
    margin-right: 0.0533rem;
  }
  .sui_icon_flashsale_15px {
    .font-dpr(30px);
    color: @sui_color_flash;
  }
  .sui_icon_time_15px {
    .font-dpr(30px);
    color: @sui_color_discount;
  }
  &__unknown-price {
    .font-dpr(28px);
    display: block;
    width: 100%;
    color: #d53333;
    font-weight: bold;
  }
  &__sale-price {
    display: inline-block;
    color: #222;
    font-weight: bold;
    &_promo,
    &_flash {
      color: @sui_color_discount;
    }
    &_sheinclub {
      color: @sui_color_club_rosegold_dark1;
    }
  }
  &__origin-price {
    .font-dpr(20px);
    display: inline-block;
    transform-origin: 0 0; // 为了scale缩放时对齐
    color: @sui_color_gray_light1; 
    text-decoration: line-through;
    margin-left: 0.1067rem;
    &_newline {
      width: 100%;
      margin: 0.0533rem 0 0 0;
    } 
  }
  .product-item__discount {
    margin-left: 0.1067rem;
  }
  &__sheinclub-discount {
    margin: 0 0 0 0.1067rem;
  }
  &__sheinclub-right {
    position: absolute;
    margin: 0;
  }
  &__camel-price {
    align-items: baseline;
    line-height: 24 / 37.5rem;
  }
}
</style>
