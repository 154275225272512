<script lang="jsx">
import { defineComponent, withModifiers } from 'vue'
import ProductItemAddToBag from './widgets/ProductItemAddToBag.vue'
import ProductItemSheinClubPrice from './widgets/ProductItemSheinClubPrice.vue'
import ProductItemVipPrice from './widgets/ProductItemVipPrice.vue'
import NormalGoodsLabel from './UI/NormalGoodsLabel.vue'
import ProductItemNormalPrice from './ProductItemNormalPrice.vue'
import ProductItemSheinClubDiscount from './ProductItemSheinClubDiscount.vue'
import ProductItemEstimatedTag from './ProductItemEstimatedTag.vue'

export default defineComponent({
  name: 'ProductItemPrices',
  props: {
    // 是否售罄
    isSoldOut: {
      type: Boolean,
      default: true
    },
    salePrice: {
      type: Object,
      default: () => ( {} )
    },
    retailPrice: {
      type: Object,
      default: () => ( {} )
    },
    // 会员专属折扣价
    exclusivePromotionPrice: {
      type: Object,
      default: () => ( {} )
    },
    config: {
      type: Object,
      default: () => ( {} )
    },
    constantData: {
      type: Object,
      default: () => ( {} )
    },
    hasFeedback: {
      type: Boolean,
      default: false
    },
    showOperatinPanel: {
      type: Boolean,
      default: false
    },
    curData: {
      type: Object,
      default: () => ( {} )
    },
    pretreatInfo: {
      type: Object,
      default: () => ( {} )
    },
    multiColumn: {
      type: Boolean,
      default: false
    },
    isPromoPrice: {
      type: Boolean,
      default: false
    },
    belowGoodsName: {
      type: Boolean,
      default: false
    },
    showMoreBtn: {
      type: Boolean,
      default: false
    },
    showNormalPrice: {
      type: Boolean,
      default: false
    },
    newFlashPromotion: {
      type: Object,
      default: () => ( {} )
    },
    sheinClubDiscountValue: {
      type: [String, Number],
      default: '',
    },
    showSmallSheinClubLabel: {
      type: Boolean,
      default: false  
    },
    bottomInfoRightGap: {
      type: String,
      default: ''
    },
    // 显示驼峰价格样式
    showCamelPrice: {
      type: Boolean,
      default: false,
    },
    flashTypeText: {
      type: String,
      default: '',
    },
    newFlashLabelUpPriceText: {
      type: String,
      default: '',
    },
    language: {
      type: Object,
      default: () => ({})
    },
    // 折扣信息
    discountInfo: {
      type: Object,
      default: () => ({})
    },
    // 来源组件
    compSrc: {
      type: String,
      default: ''
    },
    hideFsp: {
      type: Boolean,
      default: false
    }
  },
  render({ $props: props, $slots: slots, $data: data, $attrs: listeners }) {
    const { config, constantData, curData, pretreatInfo, newFlashPromotion, showNormalPrice, isSoldOut, salePrice, retailPrice, showMoreBtn, multiColumn, belowGoodsName, isPromoPrice, sheinClubDiscountValue, showSmallSheinClubLabel, exclusivePromotionPrice, bottomInfoRightGap, showCamelPrice, flashTypeText, newFlashLabelUpPriceText, language, discountInfo, compSrc, hideFsp } = props || {}
    const { GB_cssRight, IS_RW, PUBLIC_CDN } = constantData || {}

    const { showAddBagAfterGoodsPrice } = config || {}
    // 降价金额
    const discountAmount = curData?.discountAmount || {}
    // 快加车icon去掉背景
    const noIconBackground = showAddBagAfterGoodsPrice

    // 付费会员
    const sheinClubPromotionInfo = curData.sheinClubPromotionInfo || {}
    const sheinClubPrice = sheinClubPromotionInfo.price || {}
    const needSuggestedSalePrice = sheinClubPromotionInfo.hasOwnProperty('isInversion')
    const sheinClubRetailPrice = (() => {
      const { amount: sheinClubPriceAmount } = sheinClubPrice || {}
      const { amount: retailPriceAmount, amountWithSymbol: retailAmountWithSymbol } = retailPrice || {}
      if (needSuggestedSalePrice) {
        const isInversion = Number(sheinClubPromotionInfo.isInversion) === 1 // true为售价大于合规价
        if (isInversion) return ''

        const suggestedSalePriceWithSymbol = sheinClubPromotionInfo?.suggestedSalePrice?.amountWithSymbol
        return suggestedSalePriceWithSymbol ? suggestedSalePriceWithSymbol : ''
      } else {
        return Number(sheinClubPriceAmount) < Number(retailPriceAmount) ? retailAmountWithSymbol : ''
      }
    })()
    // const showSheinClubDisount = config.showSheinClubDiscountValue && sheinClubDiscountValue && !config.standardPrice
    const showSheinClubDisount = false
    // 新型闪购
    const showNewFlashLabelBelowPrice = config.showNewFlashLabelBelowPrice && flashTypeText && !config.standardPrice
    // 到手价的一些信息
    const { estimatedPrice, thresholdPrice, satisfied, showABPrice } = curData.estimatedPriceInfo || {}
    const estimatedPriceWithSymbol = estimatedPrice?.amountWithSymbol // 到手价
    const thresholdPriceWithSymbol = thresholdPrice?.amountWithSymbol // 门槛价
    const showEstimatedTag = !showSheinClubDisount && (config.showEstimatedPrice || config.showEstimatedPriceReachThreshold || showABPrice)
      ? satisfied === 1
        ? estimatedPriceWithSymbol
        : estimatedPriceWithSymbol && thresholdPriceWithSymbol
      : null
    const showPriceHolder = config.showSheinClubPrice || showNormalPrice
    const { size, backgroundColor } = config?.style?.addBagIcon || {}
    const addBagIconStyle = {
      size,
      backgroundColor,
    }
    const showAddBagIcon = ((config.showAddBagBtn && config.showLikeBtn) || config.showAddBagAfterGoodsPrice) && !isSoldOut && showNormalPrice && salePrice.amountWithSymbol

    let iconEl = null
    if (showAddBagIcon) {
      // 加车 icon
      iconEl = (
        <ProductItemAddToBag
          no-icon-background={noIconBackground}
          box-shadow={false}
          size={addBagIconStyle.size === 'middle' ? 'middle' : 'big'}
          backgroundColor={addBagIconStyle.backgroundColor}
          is-rw={IS_RW}
          onOpenQuickAdd={listeners['onOpenQuickAdd']}
        />
      )
    } else if (!config.showAddBagAfterGoodsPrice && showMoreBtn && showNormalPrice) {
      // 更多icon
      iconEl = (
        <i
          class="suiiconfont sui_icon_more_20px j-feedback-btn"
          onClick={withModifiers(listeners['onClickMoreBtn'], ['stop'])}
        ></i>
      )
    }
    return (
      <div
        class={[
          data.class,
          data.staticClass,
          'product-item__prices',
          {
            'product-item__prices_soldout': isSoldOut,
            'product-item__prices_holder': showPriceHolder && !multiColumn,
            'product-item__prices_small-holder': showPriceHolder && multiColumn,
            'product-item__prices_smaller-margin': belowGoodsName
          }
        ]}
      >
        {
          <div class="product-item__normal-price">
            {/* 普通价格（售卖价和零售价） */}
            {
              slots.price ? slots.price() : showNormalPrice
                ? (
                  <ProductItemNormalPrice
                    new-flash-promotion={newFlashPromotion}
                    config={config}
                    sale-price={salePrice}
                    retail-price={retailPrice}
                    discount-amount={discountAmount}
                    pretreat-info={pretreatInfo}
                    multi-column={multiColumn}
                    constant-data={constantData}
                    show-camel-price={showCamelPrice}
                    is-promo-price={isPromoPrice}
                    shein-club-price={sheinClubPrice}
                    shein-club-discount-value={sheinClubDiscountValue}
                    show-small-shein-club-label={showSmallSheinClubLabel}
                    bottom-info-right-gap={bottomInfoRightGap}
                    new-flash-label-up-price-text={newFlashLabelUpPriceText}
                    discount-info={discountInfo}
                    has-right-icon={!!iconEl}
                    cur-data={curData}
                    comp-src={compSrc}
                    hide-fsp={hideFsp}
                  />
                ) : null
            }
            {/* 商品榜单热度icon */}
            {
              slots.score ? slots.score() : null
            }
            { iconEl }
          </div>
        }
        {
          showEstimatedTag 
            ? (
              <ProductItemEstimatedTag 
                language={language}
                estimated-price-with-symbol={estimatedPriceWithSymbol}
                threshold-price-with-symbol={thresholdPriceWithSymbol}
                satisfied={satisfied}
                config={config}
                showABPrice={showABPrice}
              />
            ) : null
        }
        {/* 新型闪购标签 */}
        {
          showNewFlashLabelBelowPrice 
            ? (
              <NormalGoodsLabel 
                type="newFlash"
                label-text={flashTypeText}
              />
            ) : null
        }
        {/* 付费会员折扣 */}
        {
          showSheinClubDisount
            ? (
              <ProductItemSheinClubDiscount
                discount-value={sheinClubDiscountValue}
                public-cdn={PUBLIC_CDN}
                type={multiColumn ? 'small' : 'big'}
              />
            )
            : null
        }
        {/* 付费会员价格 */}
        {
          config.showSheinClubPrice
            ? (
              <ProductItemSheinClubPrice
                shein-club-price={sheinClubPrice}
                shein-club-retail-price={sheinClubRetailPrice}
                public-cdn={PUBLIC_CDN}
              />
            )
            : null
        }
        {/* vip会员专属价格 */}
        {
          exclusivePromotionPrice && exclusivePromotionPrice.amountWithSymbol && !config.hideS3VipPrice
            ? (
              <ProductItemVipPrice
                is-rw={IS_RW}
                amount-with-symbol={exclusivePromotionPrice.amountWithSymbol}
                css-right={GB_cssRight}
              />
            )
            : null
        }
      </div>
    )
  },
})
</script>

<style lang="less">
.product-item {
  &__prices {
    position: relative;
    text-align: left;
    font-size: 0; // 解决display: inline-block缝隙
    &_holder {
      min-height: 0.4533rem;
    }
    &_small-holder {
      min-height: 0.3733rem;
    }
    &_soldout {
      opacity: .3;
    }
    &_smaller-margin {
      margin-top: 0.1067rem;
    }
  }
  &__normal-price {
    .flexbox();
    .align-center();
    .space-between();
    position: relative;
    .sui_icon_more_20px { /* stylelint-disable-line */
      position: absolute;
      top: 0;
      right: 0;
      height: 0.4533rem;
      line-height: 0.4533rem;
      color: #959595;
    }
  }
  &__newflash-label {
    margin-top: 0.0533rem;
  }
}
</style>
