<template>
  <div 
    class="price-placeholder"
    :style="[isShowLabel ? { height: '0.1067rem' } : {}]"
  ></div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'PricePlaceholder',
  data() {
    return {
      isShowLabel: true,
    }
  },
  mounted() {
    this.isShowLabel = false
  },
})
</script>
