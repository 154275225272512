<script lang="jsx">
import { defineComponent } from 'vue'
import DiscountLabel from './UI/DiscountLabel.vue'

export default defineComponent({
  name: 'ProductItemImgTopLeft',
  props: {
    // 当前商品项数据
    curData: {
      type: Object,
      default: () => ({}),
    },
    constantData: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    // 是否是闪购
    pretreatInfo: {
      type: Object,
      default: () => ( {} )
    },
    config: {
      type: Object,
      default: () => ( {} )
    },
    multiColumn: {
      type: Boolean,
      default: false
    },
    // 折扣信息
    discountInfo: {
      type: Object,
      default: () => ({})
    },
    isFlashSale: {
      type: Boolean,
      default: false,
    },
    showDiscountLabel: {
      type: [Object, Boolean],
      default: false,
    },
    showHotTag: {
      type: [Number, Boolean], // TSP标签服务获取是否展示hot标
      default: 0
    },
  },
  render({ $props: props }) {
    const { constantData, pretreatInfo, config, multiColumn, discountInfo } = props
    const { IS_RW } = constantData || {}
    // 闪购
    const isFlashSale = props.isFlashSale
    // 图片左上角标
    const imgUpperLeft = pretreatInfo?.imgUpperLeft || null
    // 图片左上角标样式
    const imgUpperLeftBadgeStyle = {
      color: imgUpperLeft?.fontColor || '',
      backgroundColor: imgUpperLeft?.backgroundColor || '',
    }
    // 左上角新增hot标
    const showHotTag = props.showHotTag
    const HotLanText = props.language.SHEIN_KEY_PWA_25731 || 'Hot'
    const newFlashSaleStyle = config.style?.discountLabel === 'flashSale'
    // 使用闪购页新的折扣标样式
    const useNewFlashStyle = isFlashSale && newFlashSaleStyle
    // 显示闪购新样式闪电icon
    const showNewFlashLightIcon = config.style?.showNewFlashLightIcon
    // 折扣标签类型（控制样式的）
    let discountLabelType = 'default'
    // 新型闪购
    if (discountInfo?.newFlashDiscount) {
      discountLabelType = 'promo'
    }
    // 闪购
    if (isFlashSale) {
      discountLabelType = 'flash'
    }
    if (props.showDiscountLabel) {
      // 折扣标签
      return (
        <DiscountLabel
          class={['product-item__topleft-discount', { 'flash-with-icon': useNewFlashStyle }]}
          type={discountLabelType}
          multi-column={multiColumn}
          discount-info={discountInfo}
          isRW={IS_RW}
        >
          { (useNewFlashStyle || showNewFlashLightIcon) ? <i class="suiiconfont sui_icon_flashsale_15px"></i> : null }
        </DiscountLabel>
      )
    } else if (imgUpperLeft) {
      // ccc-tsp 角标
      return (
        <div
          class={[
            'product-item__topleft-badge',
            {
              'product-item__topleft-badge_small': multiColumn
            }
          ]}
          style={imgUpperLeftBadgeStyle}
        >
          { imgUpperLeft.text }
        </div>
      )
    } else if(showHotTag === 1) {
      // 新增hot标
      return (
        <div
          class={[
            'product-item__topleft-badge',
            'product-item__hot-badge'
          ]}
        >
          { HotLanText }
        </div>
      )
    } else {
      return null
    }
  },
})
</script>

<style lang="less">
.product-item {
  &__third-part-label {
    position: absolute;
    left: 0 /* rtl: ignore */;
    top: 4px;
    z-index: @zindex-hack;
    background-color: #333;
    color: #fff;
    font-size: 10px;
    line-height: 1.35;
    text-align: center;
    padding: 0 2px;
  }

  &__topleft-discount {
    &.product-item__discount {
      position: absolute;
      left: 0 /* rtl: ignore */;
      top: 4px;
      z-index: @zindex-hack;
      /*rw:begin*/
      top: 0;
      font-family: 'Adieu';
    }
    &.flash-with-icon.product-item__discount {
      padding: 0.0533rem 0.04rem;
      top: 0;
      left: 0 /* rtl: ignore */; 
      line-height: 1;
    }
  }

  &__topleft-badge {
    .font-dpr(20px);
    position: absolute;
    top: 4px;
    left: 0 /*rtl:ignore*/;
    padding: 0 .1067rem;
    height: 0.4267rem;
    line-height: 0.4267rem;
    z-index: @zindex-hack;
    /*rw:begin*/
    top: 0;
    font-family: 'Adieu';
    &_small {
      .font-dpr(18px);
      height: 0.32rem;
      line-height: 0.32rem;
    }
  }
  &__hot-badge {
    .font-dpr(20px);
    font-weight: bold;
    background: #FA6338;
    color:#FFF;
  }
}
</style>
