<script lang="jsx">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ProductItemSheinClubPrice',
  props: {
    publicCdn: {
      type: String,
      default: ''
    },
    sheinClubPrice: {
      type: Object,
      default: () => ({})
    },
    sheinClubRetailPrice: {
      type: String,
      default: ''
    }
  },
  render({ $props: props }) {
    const { publicCdn, sheinClubPrice, sheinClubRetailPrice } = props || {}
    return (
      <div class="shein-club">
        <div class="shein-club__prime-price-ctn">
          <img
            class="shein-club__logo"
            src={`${publicCdn}/pwa_dist/images/pay_vip/sui_icon_paidmember@3x-8b5eaa1f7a.png`}
          />
          {sheinClubPrice.amountWithSymbol ?
            <div class="shein-club__prime-price">{sheinClubPrice.amountWithSymbol}</div> : null}
        </div>
        {sheinClubRetailPrice ? <div class="shein-club__sale-price">{sheinClubRetailPrice}</div> : null}
      </div>
    )
  },
})
</script>

<style lang="less">
.shein-club {
  margin-top: -0.1067rem;
  &__prime-price-ctn {
    .flexbox();
    .align-center();
  }
  &__logo {
    width: 0.3733rem;
    margin-right: 0.0533rem;
  }
  &__prime-price {
    font-size: 14px;
    line-height: 0.4533rem;
    color: #FFBA97;
    margin-right: 0.0533rem;
    font-weight: bold;
  }
  &__sale-price {
    font-size: 12px;
    line-height: 14px;
    text-decoration: line-through;
    color: #FFFFFF;
    opacity: 0.5;
  }
}
</style>
