<script lang="jsx">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ProductItemColorItem',
  props: {
    color: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  render({ $props: props, $data: data }) {
    return (
      <div
        class={[
          'product-item__color-item',
          data.class,
          data.staticClass,
          {
            'lazyload': !!props.color,
          },
        ]}
        style={[
          data.style,
          data.staticStyle,
          {
            zIndex: 3 - props.index,
          },
        ]}
        data-background-image={props.color}
      ></div>
    )
  },
})
</script>

<style lang="less">
.product-item__color-item {
  width: 0.2667rem;
  height: 0.2667rem;
  border-radius: 50%;
  border: 0.0133rem solid #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>
