<script lang="jsx">
import { defineComponent } from 'vue'
import { template } from '@shein/common-function'

export default defineComponent({
  name: 'ProductItemEstimatedTag',
  props: {
    config: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    estimatedPriceWithSymbol: {
      type: String,
      default: ''
    },
    thresholdPriceWithSymbol: {
      type: String,
      default: ''
    },
    satisfied: {
      type: Number,
      default: -1
    },
    showABPrice: {
      type: Boolean,
      default: false
    }
  },
  render({ $props: props }) {
    const { config, language, estimatedPriceWithSymbol, thresholdPriceWithSymbol, satisfied = -1, showABPrice } = props
    const { showEstimatedPrice, showEstimatedPriceReachThreshold } = config || {}
    const showEstimatedPriceUpThreshold = satisfied === 1 && estimatedPriceWithSymbol && (showEstimatedPriceReachThreshold || showEstimatedPrice || showABPrice)
    const showEstimatedPriceBelowThreshold = satisfied === 0 && estimatedPriceWithSymbol && thresholdPriceWithSymbol && showEstimatedPrice
    const [prefixEstimatedText, suffixEstimatedText] = language.SHEIN_KEY_PWA_24939.split('{0}')
    let estimatedEl = null
    if (showEstimatedPriceUpThreshold) { // 满足门槛
      estimatedEl = (
        <div class="product-item__reach-estimated">
          <i class="suiiconfont sui_icon_coupon_discount_16px"></i>
          {
            prefixEstimatedText 
              ? <span class="estimated-price__text">{ prefixEstimatedText.trim() }</span>
              : null
          }
          <span class="estimated-price__price">{ estimatedPriceWithSymbol }</span> 
          {
            suffixEstimatedText 
              ? <span class="estimated-price__text">{ suffixEstimatedText.trim() }</span>
              : null
          }
        </div>
      )
    } else if (showEstimatedPriceBelowThreshold) { // 不满足门槛
      estimatedEl = (
        <div class="product-item__noreach-estimated">
          <span class="estimated-price__price">{ estimatedPriceWithSymbol }</span>
          <span class="estimated-price__text">{ template(thresholdPriceWithSymbol, language.SHEIN_KEY_PWA_24940) }</span>
        </div>
      )
    }
    return estimatedEl
  },
})
</script>

<style lang="less">
.product-item {
  &__reach-estimated,
  &__noreach-estimated {
    display: inline-flex;
    .align-center();
    max-width: 100%;
    height: 0.5333rem;
    padding: 0 0.08rem 0 0.0533rem;
    margin-top: 0.08rem;
    background: #FFECE9;
    color: @sui_color_discount;
    border-radius: 0.0533rem;
    .estimated-price {
      &__text {
        .font-dpr(20px);
        .text-overflow();
      }
      &__price {
        .font-dpr(24px);
        font-weight: bold;
        margin-left: 0.0533rem;
      }
    }
  }
  &__reach-estimated {
    position: relative;
    padding-left: 20px;
    .suiiconfont {
      position: absolute;
      left: 0.0533rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__noreach-estimated {
    padding: 0 0.1067rem;
    .estimated-price {
      &__text {
        line-height: 0.32rem;
        margin-left: 0.1067rem;
        padding-left: 0.1067rem;
        border-left: 1px solid rgba(250, 99, 56, 0.5);
      }
      &__price {
        margin-left: 0;
      }
    }
  }
}
</style>
