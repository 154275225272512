<script lang="jsx">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ProductItemUserCommentKeywordsLabel',
  props: {
    /**
     * @params {Object} 
        labelLang  展示文案
        labelLangEn  英文文案（埋点用）
        count: 评论数量
        hreview: 推荐返回评论
     */
    userCommentKeywordsLabelInfo: {
      type: Object,
      default: () => ({})
    }
    
  },
  render({ $props: props }) {

    // 用户评论关键词标签 推荐获取：  services/product_atom/tools
    return (
      <div 
        class="product-item__user-commentLabel"
      >
        {props.userCommentKeywordsLabelInfo.labelLang}
      </div>
    )
  },
})
</script>

<style lang="less">
.product-item {
  &__user-commentLabel {
    .font-dpr(20px);
    .text-overflow();
    position: relative;
    display: inline-block;
    max-width: 100%;
    height: 0.4267rem;
    line-height: 0.4267rem;
    margin-top: 0.1067rem;
    color: #a86104;
  }
}
</style>
