<template>
  <div
    v-if="showSearchFilterWords"
    class="product-item__search-filter"
    :style="goodsBottomStyle"
  >
    <template v-for="(item, idx) in searchFilters">
      <div
        v-if="item && finalSearchFiltersIndex[idx]"
        :key="item.filterId"
        :class="['search-filter__label', `searchFilter${idx}`]"
        @click.stop="clickSearchFilter({ item, idx })"
      >
        <span class="search-filter__label-text">{{ item.filterWord }}</span>
        <i class="suiiconfont sui_icon_search_16px"></i>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import { clickSearchFilterAna } from '../analysis'
import labelsCalcMixin from '../js/labelsCalcMixin'
import { formatSearchItem, handleSearchFilterQuery } from 'public/src/pages/product_list_v2/js/search.common'

export default defineComponent({
  name: 'BottomLabelsSearchFilter',
  mixins: [labelsCalcMixin],
  emits: ['clickSearchFilter'],
  props: {
    pretreatInfo: {
      type: Object,
      default: () => ({})
    },
    itemWidth: {
      type: Number,
      default: 0
    },
    isSoldOut: {
      type: Boolean,
      default: false
    },
    searchFilters: {
      type: Array,
      default: () => []
    },
    // 当前项的索引
    index: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      isSearchFilter: true, // 给labelsCalcMixin使用
      finalSearchFiltersIndex: {}, // 根据宽度计算后，最终展示得下的分布式筛选的index
    }
  },
  computed: {
    ...mapState(['searchFilterWords']),
    showSearchFilterWords () {
      return this.searchFilterWords?.length < 4 && this.searchFilters?.length
    }
  },
  methods: {
    async clickSearchFilter ({ item, idx }) {

      formatSearchItem('SEARCH_FILTER_LABEL', item)
      if (item.filterId && item.filterWord) {
        // 埋点
        this.clickAna({ item, idx })

        const query = handleSearchFilterQuery({ 
          searchItem: item,
          searchFilterWords: this.searchFilterWords,
          query: this.$route.query
        })
        this.$emit('clickSearchFilter', query)

      }
    },
    clickAna ({ item, idx }) {
      let filterIndex = idx
      const getFilterIndex = (beforeIdx) => {
        if (beforeIdx <= 0) return

        if (!this.finalSearchFiltersIndex[beforeIdx]) {
          filterIndex -= 1
        }
        getFilterIndex(beforeIdx - 1)
      }
      getFilterIndex(idx - 1)

      let { id, dataSource } = item
      clickSearchFilterAna({ id, itemIndex: this.index + 1, filterIndex: filterIndex + 1, dataSource })
    }
  },
})
</script>

<style lang="less">
.product-item {
  &__search-filter {
    .flexbox();
    .search-filter {
      &__label {
        .flexbox();
        .align-center();
        max-width: 100%;
        height: 16px;
        line-height: 16px;
        padding: 0 0.1067rem;
        margin-top: 0.1067rem;
        color: @sui_color_gray_dark2;
        background: @sui_color_gray_weak2;
        &:not(:last-of-type) {
          margin-right: 8px;
        }
        .suiiconfont.sui_icon_search_16px { /* stylelint-disable-line */
          display: inline-block;
          height: 12px;
          line-height: 12px;
          font-size: 12px;
          margin-left: 0.0533rem;
        }
      }
      &__label-text {
        .text-overflow();
        // 4.52rem -> 2图宽度，12px -> 图标宽度，0.2134rem -> 左右padding，0.0533rem -> 图标左边距
        max-width: calc(4.52rem - 12px - 0.2134rem - 0.0533rem);
      }
    }
  }
}
</style>
