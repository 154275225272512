<template>
  <div
    class="product-item-decisionattr"
    :class="wrapperCls"
  >
    {{ currText }}
  </div>
</template>
<script>
import { defineComponent } from 'vue'
/**
 * @file 决策属性组件
 */

export default defineComponent({
  name: 'ItemDecisionAttr',
  props: {
    attrList: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      default: () => ({})
    },
    titleVisible: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    currText () {
      return this.attrList.map(item => item.name).join(' | ')
    },
    wrapperCls () {
      return {
        'product-item-decisionattr__with-title': this.titleVisible,
      }
    },
  },
})
</script>

<style lang="less" scoped>
.product-item-decisionattr {
  margin-top: 0.15rem;
  .line-camp(1);
  .font-dpr(20px);
  line-height: 0.37rem;
  color: #767676;
  display: block;
  white-space: nowrap;

  &__with-title {
    margin-top: 0.05rem;
  }
}
</style>
