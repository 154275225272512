<script lang="jsx">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ProductItemReducePrice',
  props: {
    reducePriceLabel: {
      type: String,
      default: '',
    },
  },
  render({ $props: props, $data: data }) {
    return (
      <div 
        class={[
          'product-item__reduce-price',
          data.class,
          data.staticClass,
        ]}
      >
        { props.reducePriceLabel }
      </div>
    )
  },
})
</script>

<style lang="less">
.product-item {
  &__reduce-price {
    .text-overflow();
    .font-dpr(20px);
    line-height: 1.2;
    color: @sui_color_discount;
    margin-top: 0.0533rem;
  }
}
</style>
