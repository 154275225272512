<script lang="jsx">
import { defineComponent, withModifiers } from 'vue'
export default defineComponent({
  name: 'ProductItemNewFlashPrice',
  props: {
    newFlashPromotion: {
      type: Object,
      default: () => ( {} )
    },
    language: {
      type: Object,
      default: () => ( {} )
    },
    showRetailPrice: {
      type: Boolean,
      default: false
    },
    retailPrice: {
      type: Object,
      default: () => ( {} )
    },
    showMoreBtn: {
      type: Boolean,
      default: false
    }
  },
  render({ $props: props, $data: data, $attrs: listeners }) {
    const flashPrice = props.newFlashPromotion?.price?.amountWithSymbol
    const flashTypeText = props.newFlashPromotion?.flash_type == 2 ? props.language.SHEIN_KEY_PWA_21928 : props.newFlashPromotion?.flash_type == 3 ? props.language.SHEIN_KEY_PWA_21932 : ''
    const retailPriceAmount = props.retailPrice?.amount
    const newFlashPriceAmount = props.newFlashPromotion?.price?.amount
    const showRetail = props.showRetailPrice && retailPriceAmount && Number(retailPriceAmount) > Number(newFlashPriceAmount)

    return (
      flashPrice
        ?
        <div class={['new-flash', data.class, data.staticClass]} style={[data.style, data.staticStyle]}>
          <div class="new-flash__type">
            {flashTypeText}
          </div>
          <div class="new-flash__price">
            <div class="new-flash__price-wrapper">
              <div class="new-flash__flash-price">
                {flashPrice}
              </div>
              {
                showRetail
                  ?
                  <div
                    class="new-flash__retail-price"
                  >
                    {props.retailPrice.amountWithSymbol}
                  </div>
                  :
                  null
              }
            </div>
            {
              props.showMoreBtn
                ?
                <i
                  class="suiiconfont sui_icon_more_20px"
                  onClick={withModifiers(listeners['onClickMoreBtn'], ['stop'])}
                ></i>
                :
                null
            }
          </div>
        </div>
        :
        null
    )
  },
})
</script>

<style lang="less">
.new-flash {
  background: @sui_color_promo_bg;
  color: @sui_color_promo;
  padding: 0.1067rem;
  &__type {
    .font-dpr(20px);
    line-height: 0.32rem;
    margin-bottom: 0.0533rem;
  }
  &__price {
    .flexbox();
    .align-center();
    .space-between();
    .suiiconfont.sui_icon_more_20px { // 为了更高优先级
      bottom: 0.1067rem;
    }
  }
  &__price-wrapper {
    .flexbox();
    .align-center();
  }
  &__flash-price {
    .font-dpr(28px);
    line-height: 0.4533rem;
    font-weight: bold;
  }
  &__retail-price {
    .font-dpr(20px);
    color: #999;
    margin-left: 2px;
    text-decoration: line-through;
  }
}
</style>
