<template>
  <div
    class="product-item__normal-labels"
  >
    <!-- 各种标签， 优先级：新型闪购 > 本地卖家，快速发货 二选一 > 减价（price cut） > promotion > 卖点 > cccTsp标签 > 剩余库存 -->
    <div
      v-if="showLabels"
      class="product-item__label"
      :class="{
        'product-item__label_two': !showOneLine,
        'product-item__label_small-margin': multiColumn,
        'product-item__label_four': hasMultipleProducts
      }"
      :style="goodsBottomStyle"
    >
      <!-- 新型闪购（新人，专属） -->
      <NormalGoodsLabel 
        v-if="showNewFlash"
        type="newFlash"
        :label-text="labelsShow.flashTypeText"
      />

      <!-- 本地卖家 -->
      <NormalGoodsLabel 
        v-if="showLocalSeller"
        type="localSeller"
        :label-text="labelsShow.localMallName"
      />
      <!-- 快速发货 -->
      <NormalGoodsLabel 
        v-else-if="showQuickShip"
        html
        type="quickShip"
        :label-text="language.SHEIN_KEY_PWA_22276"
      />
      <!-- 减价（price cut） -->
      <NormalGoodsLabel 
        v-if="showPriceCut"
        type="priceCut"
        :label-text="language.SHEIN_KEY_PWA_18817"
      />
      <!-- promotion -->
      <NormalGoodsLabel 
        v-if="showPromotion"
        type="promotion"
        :label-text="labelsShow.promotionLabel"
      />
      <!-- 卖点 -->
      <template v-if="showSellingPointsOnLabel">
        <template v-for="(item, idx) in sellingPoints">
          <NormalGoodsLabel 
            v-if="item && finalSellingPointIndex[idx]"
            :key="item.tag_val_id"
            :type="config.showGreySellingPoint && !constantData.IS_RW ? 'newSellingpoint' : 'sellingpoint'"
            :class="['sellingPoint' + idx]"
            :label-text="item.tag_val_name_lang"
          />
        </template>
      </template>
      <!-- ccc-tsp角标 -->
      <NormalGoodsLabel 
        v-if="showCccTspBadge"
        type="cccTspLabel"
        :style="goodsBottomBadgeStyle"
        :label-text="labelsShow.cccTspBadges"
      />
      <!-- 剩余库存, 0-10(不含首尾) 例: only 2 left -->
      <NormalGoodsLabel 
        v-if="showStockLeft"
        type="stockleft"
        :label-text="labelsShow.stockLeft"
      />
    </div>
    <!-- 用户行为数据标签 -->
    <NormalGoodsLabel 
      v-if="config.showUserAct && userActTip"
      type="userAct"
      :label-text="userActTip"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import NormalGoodsLabel from './UI/NormalGoodsLabel.vue'
import labelsCalcMixin from '../js/labelsCalcMixin'

export default defineComponent({
  name: 'ProductItemNormalLabels',
  components: {
    NormalGoodsLabel
  },
  mixins: [labelsCalcMixin],
  props: {
    config: {
      type: Object,
      default: () => ( {} )
    },
    pretreatInfo: {
      type: Object,
      default: () => ( {} )
    },
    language: {
      type: Object,
      default: () => ( {} )
    },
    showOneLine: {
      type: Boolean,
      default: false
    },
    isSoldOut: {
      type: Boolean,
      default: false
    },
    constantData: {
      type: Object,
      default: () => ( {} )
    },
    curData: {
      type: Object,
      default: () => ( {} )
    },
    multiColumn: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: -1
    },
    showSellingPointsOnLabel: {
      type: Boolean,
      default: false
    },
    labelsShow: {
      type: Object,
      default: () => ({})
    },
    goodsBottomBadgeStyle: {
      type: Object,
      default: () => ({})
    },
    hideLabels: {
      type: Boolean,
      default: false
    },
    itemWidth: {
      type: Number,
      default: 0
    },
    userActTip: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      newFlashLabelWithinWidth: true,
      priceCutWithinWidth: true,
      promotionWithinWidth: true,
      cccTspBadgeWithinWidth: true,
      localSellerWithinWidth: true,
      stockLeftWithinWidth: true,
      showUserActLabel: ''
    }
  },
  computed: {
    showPriceCut () {
      return this.labelsShow.priceCut && this.priceCutWithinWidth
    },
    showLocalSeller () {
      return this.labelsShow.localMallName && this.localSellerWithinWidth
    },
    showQuickShip () {
      return this.labelsShow.quickShip && this.localSellerWithinWidth
    },
    showPromotion () {
      return this.labelsShow.promotionLabel && this.promotionWithinWidth
    },
    // 卖点
    sellingPoints () {
      return this.labelsShow?.sellingPoints || []
    },
    showCccTspBadge () {
      return this.labelsShow.cccTspBadges && this.cccTspBadgeWithinWidth
    },
    // 仅romwe
    showStockLeft () {
      return this.labelsShow.stockLeft && this.stockLeftWithinWidth
    },
    showNewFlash () {
      return this.labelsShow.flashTypeText && this.newFlashLabelWithinWidth
    },
    hasMultipleProducts() {
      return this.config?.style?.hasMultipleProducts
    }
  },
})
</script>

<style lang="less">
.product-item {
  &__label {
    .flexbox();
    font-size: 0;
    // margin-top: 0.1067rem;
    >div:not(:last-of-type) {
      margin-right: 8px;
    }
    &_small-margin {
      margin-top: 0.1067rem;
    }
    &_two {
      flex-wrap: wrap;
      max-height: 1.0667rem;
      overflow: hidden;
    }
    &_four {
      margin-top: -0.1rem;
      opacity: 1;
    }
  }
}
</style>
