<script lang="jsx">
import { defineComponent } from 'vue'
import { transformImg } from '@shein/common-function'

export default defineComponent({
  name: 'ProductItemRomweSeries',
  props: {
    seriesOrBrandLogoBottom: {
      type: String,
      default: ''
    },
    plusSize: {
      type: String,
      default: ''
    },
    romweOriginals: {
      type: String,
      default: ''
    },
    constantData: {
      type: Object,
      default: () => ( {} )
    }
  },
  render({ $props: props }) {
    return (
      <div
        class={[
          'product-item__badge_line',
          {
            'product-item__badge_series': props.seriesOrBrandLogoBottom
          }
        ]}
      >
        {
          props.seriesOrBrandLogoBottom 
            ? (
              <img
                src={props.constantData.LAZY_IMG_PIXEL}
                alt=''
                data-src={transformImg({ img: props.seriesOrBrandLogoBottom })}
                class="lazyload"
              />
            ) 
            : props.plusSize || props.romweOriginals || null
        }
      </div>
    )
  },
})
</script>

<style lang="less">
.product-item__badge_line {
  width: 100%;
  height: 0.48rem;
  line-height: .48rem;
  text-align: center;
  .text-overflow();
  color: #333;
  background-color: #fff;
  opacity: .8;
  > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.product-item__badge_series {
  height: 25px;
  line-height: 25px;
}
</style>
