import { nextTick } from 'vue'
import fastdom from 'fastdom'
import fastdomPromised from 'fastdom/extensions/fastdom-promised'
const myFastdom = fastdom.extend(fastdomPromised)


function getElementWidth(element) {
  return new Promise((resolve) => {
    const widthObserver = new IntersectionObserver((entries) => {
      // Loop through all `entries` returned by the observer
      const boundsArr = []
      for (const entry of entries) {
        // The `entry.boundingClientRect` is where all the dimensions are stored
        const bounds = entry.boundingClientRect
        // Log the `bounds` for every element
        boundsArr.push(bounds)
        // Then do whatever with `bounds`
      }
      // Disconnect the observer to stop from running in the background
      widthObserver.disconnect()
      const width = boundsArr[0].width
      // console.log(width)
      resolve(width)
    })
    widthObserver.observe(element)
  })
}

export default {
  emits: ['exposeSellingPoint', 'showCccTspBadge', 'showQuickShip', 'showSearchFilter'],
  data() {
    return {
      showSellingPointQuantity: 2, // 最多能展示的卖点标签数量
      finalSellingPointIndex: {}, // 根据宽度计算后，最终展示得下的卖点的index
      finishBottomElCalcWidth: false,
    }
  },
  computed: {
    goodsBottomStyle() {
      return this.finishBottomElCalcWidth ? { opacity: 1 } : { opacity: 0 }
    },
    showLabels() {
      let showSellingPoint = false
      if (this.showSellingPointsOnLabel && Object.keys(this.finalSellingPointIndex).length) {
        showSellingPoint = true
      }
      const showNormalLabels = (!this.hideLabels && !this.isSoldOut && (this.showNewFlash || this.showPromotion || showSellingPoint || this.showCccTspBadge || this.showLocalSeller || this.showStockLeft || this.showPriceCut || this.showQuickShip))

      return this.isStarRatingWithLabels ? this.hasUserBehaviorOrRankingList || showNormalLabels : showNormalLabels
    },
    hasUserBehaviorOrRankingList() {
      return !!(this.userBehaviorLabelLang || this.showRankingList || this.userCommentKeywordsLabelLang)
    },
    // 分布式筛选标签
    searchFilterLabelsReady () {
      const { searchFiltersReady } = this.pretreatInfo || {}
      return searchFiltersReady
    },
    // 评论关键字标签，行为标签，榜单，新型闪购 > 本地卖家，快速发货 二选一 > 减价（price cut） > promotion > 卖点 > cccTsp标签 > 剩余库存
    allLabelsReady() {
      const { userBehaviorLabelReady, rankingListReady } = this.pretreatInfo || {}
      const UserBehaviorOrRankingListReady = userBehaviorLabelReady && rankingListReady // 小号星级评分 (ProductItemStarRatingWithLabels.vue) 需要依赖这2个标签 

      if (this.hideLabels) return this.isStarRatingWithLabels && UserBehaviorOrRankingListReady
      
      // promotionReady 促销相关（付费会员，新型闪购，buy more save more等）
      const { localMallReady, priceCutReady, promotionReady, badgesReady, sellingPointsReady, stockLeftReady } = this.pretreatInfo || {}

      const _priceCutReady = this.config?.showPriceCut ? priceCutReady : true
      const normalLabelsReady = localMallReady && _priceCutReady && promotionReady && sellingPointsReady && badgesReady && stockLeftReady
      return this.isStarRatingWithLabels ? UserBehaviorOrRankingListReady && normalLabelsReady : normalLabelsReady
    },
    labelsReady() {
      return this.isSearchFilter ? this.searchFilterLabelsReady : this.allLabelsReady
    }
  },
  watch: {
    labelsReady: {
      handler(labelsReady) {
        this.showSellingPointQuantity = this.config?.showGreySellingPoint && !this.constantData.IS_RW ? 1 : this.showSellingPointQuantity
        labelsReady && this.handleLabels()
      },
      immediate: true
    },
    finishBottomElCalcWidth (val) {
      if (val) {
        if (!this.isSearchFilter) {
          // 卖点
          if (this.showSellingPointsOnLabel) {
            const sellingPoints = Object.keys(this.finalSellingPointIndex || {}).map(idx => this.sellingPoints[idx])
            if (sellingPoints.length) this.handleShowSellingPoint(sellingPoints)
          }
          // cccTsp标签
          this.handleShowCccTspBadge()
          // QS标签
          this.showQuickShip && this.handleShowQuickShip()
        }

        if (this.isSearchFilter) {
          // 分布式筛选标签
          const showSearchFilters = Object.keys(this.finalSearchFiltersIndex || {}).map(idx => this.searchFilters[idx])
          if (showSearchFilters.length) this.handleShowSearchFilter(showSearchFilters)
        }
      }
    },
  },
  methods: {
    async handleLabels() {
      await nextTick()

      let labelsWidthMap = {}
      let defaultW = 0
      if (this.isSearchFilter) {
        // 计算前，先让所有的分布式筛选都展示
        if (this.setFinalSearchFiltersIndex()) {
          await nextTick()
        }
        labelsWidthMap = await this.getSearchFilterLabelWidth()
      } else {
        // 计算前，先让所有的卖点都展示
        if (this.setFinalSellingPointsIndex()) {
          await nextTick()
        }
        if (this.isStarRatingWithLabels) {
          const lineW = 9 // class="line"固定宽度
          const starCommentW = await this.getWidth(this.$el.querySelector('.product-item__comment'))
          defaultW = lineW + starCommentW // 默认初始宽度
        }
        labelsWidthMap = await this.getElWidth()
      }

      let nowRow = 1
      let maxRow = 2
      const keys = Object.keys(labelsWidthMap)
      
      if (this.isStarRatingWithLabels && this.hasUserBehaviorOrRankingList ) {
        maxRow = 3
      } else if (this.isSearchFilter) {
        maxRow = 1
      }

      keys.reduce((acc, cur, index) => {
        // 用户行为标签和榜单标签不加margin-left (ProductItemStarRatingWithLabels.vue中)
        const marginL = ['userBehaviorLabel', 'rankingListLabel', 'userCommentLabel'].includes(cur) ? 0 : 8

        const currWidth = labelsWidthMap[cur] + (index !== 0 ? marginL : 0)
        this.badgeW = acc + currWidth
        if (this.badgeW <= this.itemWidth) {
          this[`${cur}WithinWidth`] = true
        } else {
          // 最后一行
          if (nowRow == maxRow) {
            if (cur.includes('sellingPoint')) {
              return delete this.finalSellingPointIndex[cur[cur.length - 1]] // 删掉放不下的卖点
            } else if (cur.includes('searchFilter')) {
              return delete this.finalSearchFiltersIndex[cur[cur.length - 1]] // 删掉放不下的分布式筛选词
            } else {
              return this[`${cur}WithinWidth`] = false
            }
          }

          // 累加行数
          // 用户行为标签和榜单，放不下不换行，超出‘...’省略号
          let newLine = false
          if (!this.showOneLine && !['userBehaviorLabel', 'rankingListLabel', 'userCommentLabel'].includes(cur)) {
            newLine = true
            this.badgeW = currWidth // 当前元素宽度设为累积宽度
            nowRow += 1
          }

          this.handleHasEnoughWidth(cur, newLine, labelsWidthMap).then(enoughWidth => {
            if(!enoughWidth) {
              if (nowRow > 1) nowRow -= 1 // 重置回上一行
              if (cur.includes('sellingPoint')) {
                delete this.finalSellingPointIndex[cur[cur.length - 1]] // 删掉放不下的卖点
              } else if (cur.includes('searchFilter')) {
                return delete this.finalSearchFiltersIndex[cur[cur.length - 1]] // 删掉放不下的分布式筛选词
              } else {
                this[`${cur}WithinWidth`] = false
              }
              this.badgeW = acc // 放不下就取上一次累加的宽度
            } else {
              if (typeof enoughWidth == 'number') {
                this.badgeW = acc + enoughWidth // 存新的累加宽度
              }
              this[`${cur}WithinWidth`] = true
            }
          })
        }

        return this.badgeW
      }, defaultW)

      this.finishBottomElCalcWidth = true
    },
    setFinalSearchFiltersIndex () {
      if (!this.searchFilters?.length) return false
      for(let i = 0; i < this.searchFilters.length; i++) {
        if (this.searchFilters[i]) this.finalSearchFiltersIndex[i] = true
      }
      return true
    },
    setFinalSellingPointsIndex () {
      if (!this.sellingPoints?.length) return false
      for(let i = 0; i < this.showSellingPointQuantity; i++) {
        if (this.sellingPoints[i]) this.finalSellingPointIndex[i] = true
      }
      return true
    },
    // 所有元素宽度
    async getElWidth() {
      const labelsWidthMap = {}
      const localSellerEl = this.$el.querySelector('.product-item__localseller-label') || this.$el.querySelector('.product-item__quickship-label')
      const newFlashLabelEl = this.$el.querySelector('.product-item__newflash-label')
      const rankingListLabelEl = this.$el.querySelector('.product-item__ranking-list')
      const userBehaviorLabelEl = this.$el.querySelector('.product-item__user-behavior')
      const priceCutEl = this.$el.querySelector('.product-item__pricecut-label')
      const promotionEl = this.$el.querySelector('.product-item__promotion-label')
      const cccTspBadgeEl = this.$el.querySelector('.product-item__ccctsp-label')
      const stockLeftEl = this.$el.querySelector('.product-item__stockleft-label')
      const userCommentLabelEl = this.$el.querySelector('.product-item__user-commentLabel')

      const [
        userBehaviorW,
        rankingListLabelW,
        newFlashLabelW,
        localSellerW,
        priceCutW,
        promotionW,
        cccTspBadgeW,
        stockLeftW,
        userCommentW
      ] = await Promise.all([
        this.getWidth(userBehaviorLabelEl),
        this.getWidth(rankingListLabelEl),
        this.getWidth(newFlashLabelEl),
        this.getWidth(localSellerEl),
        this.getWidth(priceCutEl),
        this.getWidth(promotionEl),
        this.getWidth(cccTspBadgeEl),
        this.getWidth(stockLeftEl),
        this.getWidth(userCommentLabelEl)
      ])
      
      // 用户行为标签
      if (userBehaviorW) Object.assign(labelsWidthMap, { userBehaviorLabel: userBehaviorW })
      // 用户评论关键词标签
      if (userCommentW) Object.assign(labelsWidthMap, { userCommentLabel: userCommentW })
      // 榜单
      if (rankingListLabelW) Object.assign(labelsWidthMap, { rankingListLabel: rankingListLabelW })
      // 新型闪购（新人，专属）
      if (newFlashLabelW) Object.assign(labelsWidthMap, { newFlashLabel: newFlashLabelW })
      // 本地商家
      if (localSellerW) Object.assign(labelsWidthMap, { localSeller: localSellerW })
      // 减价(price cut)
      if (priceCutW) Object.assign(labelsWidthMap, { priceCut: priceCutW })
      // 促销
      if (promotionW) Object.assign(labelsWidthMap, { promotion: promotionW })
      // 卖点
      await this.assginElWidth({ len: this.showSellingPointQuantity, prefix: 'sellingPoint', widthMap: labelsWidthMap })
      // ccc-tsp角标
      if (cccTspBadgeW) Object.assign(labelsWidthMap, { cccTspBadge: cccTspBadgeW })
      // 剩余库存
      if (stockLeftW) Object.assign(labelsWidthMap, { stockLeft: stockLeftW })

      return labelsWidthMap
    },
    // 分布式筛选各个标签的宽度
    async getSearchFilterLabelWidth () {
      const labelsWidthMap = {}

      await this.assginElWidth({ len: this.searchFilters.length, prefix: 'searchFilter', widthMap: labelsWidthMap })

      return labelsWidthMap
    },
    getWidth (el, isCompInstance) {
      const element = isCompInstance ? el?.$el : el
      return element ? getElementWidth(element) : 0
      // return myFastdom.measure(() => element?.offsetWidth || 0)
    },
    async assginElWidth ({ len, prefix, widthMap }) {
      const els = []
      for(let i = 0; i < len; i++) {
        els.push(this.$el.querySelector(`.${prefix + i}`))
      }
      const elWidths = await Promise.all(els.map(el => this.getWidth(el)))
      for (let i = 0; i < elWidths.length; i++) {
        const elWidth = elWidths[i]
        if (elWidth) {
          Object.assign(widthMap, { [prefix + i]: elWidth })
        }
      }
    },
    // 当前角标能否放得下
    async handleHasEnoughWidth (cur, newLine, labelsWidthMap) {
      const isWider = labelsWidthMap[cur] > this.itemWidth
      if (!newLine) {
        return await this.handleSpecialWidth(cur, labelsWidthMap)
      } else {
        if (isWider) {
          return await this.handleSpecialWidth(cur, labelsWidthMap)
        }

        return true
      }
    },
    async handleSpecialWidth (cur, labelsWidthMap) {
      const leftMaxW = labelsWidthMap[cur] - (this.badgeW - this.itemWidth)
      // 促销, 本地卖家角标一整行放不下才...省略
      // 用户行为标签，榜单，只要展示不下就'...'省略
      const needSetWidthEl = ['newFlashLabel', 'promotion', 'localSeller', 'userBehaviorLabel', 'rankingListLabel', 'userCommentLabel'].includes(cur)
      if (!needSetWidthEl) return
      let el = null
      if (cur == 'userBehaviorLabel') {
        el = this.$el.querySelector(`.product-item__user-behavior`)
      } else if (cur == 'rankingListLabel') {
        el = this.$el.querySelector(`.product-item__ranking-list`)
      } else if (cur == 'newFlashLabel') {
        el = this.$el.querySelector(`.product-item__newflash-label`)
      } else if (cur == 'promotion') {
        el = this.$el.querySelector(`.product-item__promotion-label`)
      } else if (cur == 'localSeller') {
        el = this.$el.querySelector(`.product-item__localseller-label`)
      } else if ( cur === 'userCommentLabel' ) {
        el = this.$el.querySelector(`.product-item__user-commentLabel`)
      }

      if (!el) return

      await myFastdom.mutate(() => {
        el.style.width = `${leftMaxW}px`
      })

      return leftMaxW
    },
    handleShowSellingPoint (sellingPoints) {
      const params = []
      sellingPoints.forEach(item => {
        params.push(['show_sellingpoint', item.tag_id, item.tag_val_id, item.tag_val_name_en].join('_'))
      })

      this.$emit('exposeSellingPoint', params.length ? params.join('|') : '')
    },
    handleShowCccTspBadge () {
      this.$emit('showCccTspBadge', this.showCccTspBadge)
    },
    handleShowQuickShip () {
      this.$emit('showQuickShip')
    },
    handleShowSearchFilter (showSearchFilters) {
      const params = []
      showSearchFilters.forEach((item, index) => {
        let { id, dataSource } = item
        params.push([this.index + 1, index + 1, dataSource, id].join('`'))
      })

      this.$emit('showSearchFilter', params.join(','))
    },
  }
}
